// This is example of menu item without group for horizontal layout. There will be no children.

// assets
import { IconBrandChrome } from "@tabler/icons-react";

// type
import { NavItemType } from "types";

import { ReactComponent as exitIcon } from "assets/images/icons/LeadingExit.svg";
import { ReactComponent as itemIcon } from "assets/images/icons/LeadingIcon.svg";
import { ReactComponent as penIcon } from "assets/images/icons/penicon.svg";
import { ReactComponent as LocalLeadingIcon } from "assets/images/icons/LocalLeadingIcon.svg";
import { ReactComponent as DashboardLEadingIcon } from "assets/images/icons/dashboardLeadingIcon.svg";
import { ReactComponent as SettingsLeadingIcon } from "assets/images/icons/SettingsLeadingIcon.svg";
import { ReactComponent as SavingsLeadingIcon } from "assets/images/icons/savingsLeadingIcon.svg";

import { IconChartInfographic } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";
// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
  IconBrandChrome,
};
const samplePage: NavItemType = {
  id: "links-other",
  type: "group",
  children: [
    /*
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: DashboardLEadingIcon,
    },

    {
      id: "general-page",
      title: <FormattedMessage id="menu_text_results" />,
      type: "item",
      url: "/",
      icon: itemIcon,
    },
    {
      id: "invoice-page",
      title: <FormattedMessage id="menu_text_billing" />,
      type: "item",
      url: "/invoice",
      icon: penIcon,
    },
    {
      id: "details-page",
      title: <FormattedMessage id="menu_text_insights" />,
      type: "item",
      url: "/details",
      icon: IconChartInfographic,
    },
    */
    {
      id: "ahorro",
      title: "Ahorro Histórico",
      type: "collapse",
      icon: SavingsLeadingIcon,
      children: [
        {
          id: "resumen",
          title: "Resumen",
          type: "item",
          url: "/",
        },
        {
          id: "invoice",
          title: "Facturación",
          type: "item",
          url: "/invoice",
        },
        {
          id: "details",
          title: "Insights",
          type: "item",
          url: "/compute",
        },
      ],
    },
    /*
    {
      id: "local",
      title: "AWS en moneda local",
      type: "item",
      url: "/settings",
      icon: LocalLeadingIcon,
    },
    */
    {
      id: "Settings",
      title: <FormattedMessage id="menu_text_settings" />,
      type: "item",
      url: "/settings",
      icon: SettingsLeadingIcon,
    },
  ],
};

export default samplePage;
